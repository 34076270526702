import { User, UserProficiency } from './graphql'

export interface Certification {
  name: string
  company: { name: string; url: string }
  credentialUrl: string
  issueDate: string // iso formatted YYYY-MM-DD
}

interface Languages {
  language: string
  proficiency?: string
}

interface GenericRecordSubType {
  _id?: string
  name?: string
}

export interface School extends GenericRecordSubType {
  slug?: string
  domains?: string[]
  verified?: boolean
}
export type Degree = GenericRecordSubType
export type Field = GenericRecordSubType

export interface EducationRecord {
  degreeType: GenericRecordSubType
  degreeField: GenericRecordSubType
  email?: string
  emailSuffix?: string
  emailVerificationCode?: string
  graduationDate: string
  school: School
  verified?: boolean
}

export interface ApprenticeOpportunity {
  title: string
  key: string
  icon: string
  color: string
  selected?: boolean
}

/* since both have the same type */
export type Tool = GenericRecordSubType

export interface Community {
  codeOfConductAgreementAccepted: boolean
  codeOfConductAgreementDate: string
  lastVisited: string
}

export interface ApprenticeProfile extends User {
  _id: string
  createdDateTime: string
  updatedDateTime: string
  name: string
  firstName: string
  lastName: string
  email: string
  dateOfBirth?: Date
  profile: { resume: string; photo: string }
  occupations?: {}

  opportunities?: ApprenticeOpportunity[]
  externalCertifications: Certification[]
  isProjectCreated: boolean
  isProfileCompleted?: boolean
  meta: {
    onboardingStep?: number
  }
  educationRecords?: EducationRecord[]
  location?: string
  aboutMe: string
  tools: Tool[]
  languages: Languages[]
  /* for the legacy cohorts */
  proficiencies: UserProficiency[]
  nationality?: string
  rating?: string
  community?: Community
}

export interface UpdateApprenticeDto {
  firstName?: string
  lastName?: string
  email?: string
  phone?: string
  phoneCountryCode?: string
  languages?: Languages[]
  phonePrefix?: string
  photo?: string
  location?: string
  nationality?: string
  isProfileCompleted?: boolean
  onboardingStep?: number
  dateOfBirth?: Date
  aboutMe?: string
  categories?: string[]
  url?: string
  resumeUrl?: string
  timezone?: string
  externalCertifications?: Certification[]
  educationRecords?: EducationRecord[]
  tools?: Tool[]
}

export enum RESUME_PROGRESS {
  RESUME_PROGRESS = 'resume-progress'
}

export enum ApprenticeResumeStatus {
  IN_PROGRESS = 'inProgress',
  FAILED = 'failed',
  PARTIAL = 'partial',
  COMPLETE = 'complete'
}
