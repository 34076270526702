import apiClient from './base'

export interface IApprenticesSettings {
  openForRoles?: boolean
  textOnJobMatch?: boolean
  profileHeadline?: string
  aboutMe?: string
  url?: string
}

export interface IApprenticesDetails {
  nationality?: string
  location?: string
  phone?: string
  languages?: string[]
  linkedIn?: string
  website?: string
  birthdate?: Date
  firstName?: string
  lastName?: string
  photo?: string
  secondaryEmail?: string
  whatsappNumber?: string
}

export interface IResumes {
  title: string
  link: string
  isPrimary: boolean
  uploadedAt?: Date
}

export interface IWorkHistory {
  title: string
  companyName: string
  companyWebsite: string
  industry: {
    _id: string
    name: string
  }
  department: {
    _id: string
    name: string
  }
  startAt: Date
  endAt: Date
}

export interface IEducationHistory {
  schoolId?: string
  schoolName: string
  degree: {
    _id: string
    name: string
  }
  field: {
    _id: string
    name: string
  }
  graduatedAt: Date
  gpa: string
  degreeAndField?: string
}

export interface IApprenticeProfessionalDetails {
  educationHistory?: IEducationHistory[]
  workHistory?: IWorkHistory[]
  resumes?: IResumes[]
  educationStatus?: string
  skills?: string[]
  tools?: string[]
  hasWorkAuthorization?: string
  isVisaRequiredForPreferredLocations?: string
  hasNoExperience?: boolean
  hasNoCertification?: boolean
}

export interface IPreferenceTheme {
  _id: string
  name: string
  interestLevel: number
}

export interface IPreferenceDepartments {
  _id: string
  name: string
}

export interface IPreferenceIndustries {
  _id: string
  name: string
}

export interface IPreferenceOccupation {
  _id: string
  name: string
}

export interface IPreferenceOpportunity {
  _id: string
  name: string
}

export enum WorkStylePreference {
  InPerson = 'IN_PERSON',
  Remote = 'REMOTE',
  Hybrid = 'HYBRID'
}

export enum ApprenticePreferredTimeToStartWork {
  SUMMER = 'summer',
  FALL = 'fall',
  WINTER = 'winter',
  SPRING = 'spring',
  ASAP = 'asap',
  PASSIVE_SEARCH = 'passiveSearch'
}

export interface IApprenticePreferencesDetails {
  departments?: IPreferenceDepartments[]
  industries?: IPreferenceIndustries[]
  jobType?: string[]
  themes?: IPreferenceTheme[]
  occupations?: IPreferenceOccupation[]
  opportunities?: IPreferenceOpportunity[]
  personality?: {
    extroverted: number
    clientFacingPreference: number
    teamPlayer: number
    competitive: number
    creative: number
    statusQuoChallenger: number
  }
  companyValues?: {
    compensation: number
    prestige: number
    socialImpact: number
    professionalDevelopment: number
    workLifeBalance: number
    upwardMobility: number
    jobStability: number
    community: number
  }
  workStyle?: WorkStylePreference[]
  salaryRange?: {
    from: number
    to: number
  }
  jobLocations?: string[]
  workStartTime?: ApprenticePreferredTimeToStartWork[]
}

export const getApprenticesProfile = (apprenticeId: string) => {
  return apiClient.get(`/apprentices/${apprenticeId}`)
}

export const updateApprenticesSettings = async (data: IApprenticesSettings) => {
  return apiClient.patch('/apprentices/settings', data).then(resp => resp.data)
}

export const updateApprenticesBasicDetails = async (
  data: IApprenticesDetails
) => {
  return apiClient.patch('/apprentices', data).then(resp => resp.data)
}

export const updateApprenticesProfessionalDetails = async (
  data: IApprenticeProfessionalDetails
) => {
  return apiClient
    .patch('/apprentices/professional-details', data)
    .then(resp => resp.data)
}

export const updateApprenticesPreferences = async (
  data: IApprenticePreferencesDetails
) => {
  return apiClient
    .patch('/apprentices/preferences', data)
    .then(resp => resp.data)
}
