export enum SubscriptionStatus {
  /**
   * - Currently an active member with renewal working
   */
  Subscribed = 'subscribed',
  /**
   * - Currently active, but has cancelled renewal so their subscription will be over
   */
  Cancelled = 'cancelled',
  /**
   * - Not an active member but had subscription in the past
   */
  Expired = 'expired',
  /**
   * - Never subscribed
   */
  Never_Subscribed = 'na',

  Trial = 'trialing'
}

export interface ActiveSubscription {
  userID: string
  planID: string
  priceID: string
  paymentDateTime: string
  expirationDateTime: string
  totalDue: SubscriptionCost
  totalPaid: SubscriptionCost
}

export interface SubscriptionCost {
  value: number
  currency: string
}
