import { get, isArray, isObjectLike, has } from 'lodash'

// errors from .catch method come with a response, having error in data attribute
export const formatErrorObject = (
  error,
  path = 'response.data',
  getFirstError = false
): string => {
  const errorObj = path ? get(error, path) : error
  let errorMessage = errorObj?.message
  if (has(errorMessage, 'message')) {
    errorMessage = errorMessage.message
  }
  if (getFirstError && isArray(errorMessage) && errorMessage.length > 0) {
    return errorMessage[0]
  }
  return isObjectLike(errorMessage) || isArray(errorMessage)
    ? JSON.stringify(errorMessage)
    : errorMessage
}
